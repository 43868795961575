<template>
  <div class="home">
    <div>
      <div class="btn_box">
          <div class="box" @click="dialogFormVisible = true">
          立即智能化
          </div>
        <el-dialog center title="留下您的信息体验智能化升级" :visible.sync="dialogFormVisible">
          <el-form :model="information" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item label="公司名称" prop="company">
              <el-input v-model="information.company" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="name">
              <el-input v-model="information.name" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="information.phone" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="mailbox">
              <el-input v-model="information.mailbox" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
          </div>
        </el-dialog>
      </div>
      <img src="../../../public/images/Platformsolution/服务器分布/2.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/Platformsolution/服务器分布/3.jpg" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "server",
  components: {
    elMenu, foot, navigation
  },
  data() {
    return{
      dialogFormVisible: false,
      labelPosition: 'right',
      // 信息提交表单
      information: {
        company: '',
        name: '',
        phone: '',
        mailbox: ''
      },
      // 表单认证
      rules: {
        name: [
          {required: true, message: '请输入您的名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入您的电话', trigger: 'blur'},
        ],
        mailbox: [
          {required: true, message: '请输入您的邮箱', trigger: 'blur'},
        ],

      },
      // formLabelWidth: '0px'
    }
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped>
.btn_box{
  width:100%;
  height:597px;
  background-image: url("../../../public/images/Platformsolution/服务器分布/1.jpg");
  top: 300px;
}
.box{
  width: 182px;
  height: 47px;
  background-color: white;
  position: relative;
  top: 332px;
  left: 875px;
  border-radius: 4px;
  text-align: center;
  line-height:50px;
  color: #24549A;
}
/deep/.el-dialog--center .el-dialog__body{
  margin-left: 20%;
}
</style>